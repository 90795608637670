<template>
  <b-container>
    <b-row v-for="(family, index) in productFamilies" :key="index" class="mb-2">
      <b-col>
        <router-link
          :to="{ name: 'inputStock.new.edit', params: { family: family.code } }"
          class="text-infos line-family shape-cell text-center mx-4 d-flex justify-content-center align-items-center"
        >
          {{ family.designation }}
        </router-link>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";

export default {
  mixins: [DetailUpdateMixin],
  computed: {
    ...mapGetters(["productFamilies"]),
  },
  async mounted() {
    this.loadOn();

    await this.$store.dispatch("getProductFamilies");

    this.loadOff();
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
